.dragDropWrapper {
  border: 2px dashed #bbb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 21px;
  color: #bbb;
  height: 235px;
}

.dragDropWrapper p {
  text-align: center;
  vertical-align: middle;
  padding: 100px 40px;
}